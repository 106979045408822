import React, { Component } from 'react'
import ApplyForm from '../components/ApplyForm'
import Layout from '../components/layout'

export default class Apply extends Component {
  render() {
    return (
      <Layout>
        <ApplyForm />
      </Layout>
    )
  }
}
