import React from 'react'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'

export default function TextInput(props) {
  return (
    <InputWrapper margin={props.margin}>
      <StyledInput
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
      />
      {props.value.length > 0 && (
        <Icon>
          <FaCheck color="#f09000" />
        </Icon>
      )}
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: ${props => (props.margin ? '40px 0' : '20px 0')};
`

const StyledInput = styled.input`
  width: 100%;
  background-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-width: 1px;
  border-bottom-color: ${props =>
    props.value.length > 0 ? '#f09000' : '#fff'};
  color: white;
  font-size: 18px;
  padding: 10px 0;
  ::placeholder {
    font-size: 18px;
  }
  &:focus {
    border-bottom: 1px solid #ff0066;
  }
`

const Icon = styled.div`
margin-left: -80px;
  /* display: ${props => (props.value.length > 0 ? 'block' : 'none')}; */
`
