import React, { useState, useEffect } from 'react'
// plugin that creates slider
import Slider from 'nouislider'
import styled from 'styled-components'
import '../../css/slider.scss'
import CB from '../Checkbox'

export default function FrelancerRemoteRate(props) {
  useEffect(() => {
    if (
      !document
        .getElementById('remoteRateSlider')
        .classList.contains('noUi-target')
    ) {
      Slider.create(document.getElementById('remoteRateSlider'), {
        start: props.remoteRate,
        connect: [false, true, false],
        step: 5,
        range: { min: 20, max: 200 },
      }).on('update', values => {
        props.getRemoteRate(values)
      })
    }
    return function cleanup() {}
  })
  return (
    <Wrapper>
      <Header>What is your hourly rate for remote work?</Header>
      <LabelWrapper>
        <Label>€{props.remoteRate[0]}</Label>
        <Label>to</Label>
        <Label>€{props.remoteRate[1]}</Label>
      </LabelWrapper>
      <SliderWrapper>
        <div id="remoteRateSlider" className="slider-primary" />

        <CB
          color="#ff9000"
          checked={props.remoteRateNotApplicable}
          onChange={() => props.getremoteRateNotApplicable()}
          label="Not Applicable"
        />
      </SliderWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

const SliderWrapper = styled.div`
  width: 100%;
  @media (max-width: 800px) {
    width: 90%;
    margin-left: 20px;
  }
`

const Header = styled.p`
  font-size: 22px;
  color: #777;
  padding-right: 2vh;
  margin-top: 0;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const Label = styled.h3`
  text-align: center;
  margin-bottom: 2vh;
  color: #999;
  @media (max-width: 800px) {
    font-size: 22px;
  }
`
