import React from 'react'
import ReactSelect from './apply page/ReactSelect'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Datetime from 'react-datetime'
import firebase from '../firebase'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import ErrorModal from './ErrorModal'
import AttachFile from '@material-ui/icons/AttachFile'
import { Link } from 'gatsby'
import Spinner from './Spinner'
import TextInput from './TextInput'
import CB from './Checkbox'
import FreelancerRemoteRate from './apply page/FreelancerRemoteRate'
import FreelancerOnSiteRate from './apply page/FreelancerOnSiteRate'
import moment from 'moment'

export default function ApplyForm() {
  const [form, setValues] = React.useState({
    name: '',
    email: '',
    telephone: '',
    linkedIn: '',
    gitHub: '',
    skillSets: '',
    password: '',
    cv: '',
  })
  const {
    name,
    email,
    telephone,
    password,
    linkedIn,
    gitHub,
    skillSets,
    availability,
  } = form
  const [share, setShare] = React.useState(false)
  const Gdpr = true
  const [cvURL, setCvURL] = React.useState('')
  const [fileName, setFileName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [remoteRate, setRemoteRate] = React.useState([20, 200])
  const [remoteRateNotApplicable, setremoteRateNotApplicable] = React.useState(
    false
  )
  const [onSiteRate, setOnSiteRate] = React.useState([20, 200])
  const [onSiteRateNotApplicable, setonSiteRateNotApplicable] = React.useState(
    false
  )

  const getRemoteRate = remoteRate => {
    setRemoteRate(remoteRate)
  }

  const getremoteRateNotApplicable = () => {
    setremoteRateNotApplicable(!remoteRateNotApplicable)
  }

  const getOnSiteRate = onSiteRate => {
    setOnSiteRate(onSiteRate)
  }

  const getonSiteRateNotApplicable = () => {
    setonSiteRateNotApplicable(!onSiteRateNotApplicable)
  }

  const [isShowing, setIsShowing] = React.useState(false)
  const onValuePass = value => {
    setValues({ ...form, skillSets: value })
  }
  const [user, setUser] = React.useState(false)

  React.useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
    })
    return () => unsubscribe()
  }, [])

  const openModalHandler = () => {
    setIsShowing(true)
  }

  const closeModalHandler = () => {
    setIsShowing(false)
  }

  const saveProfile = async () => {
    if (
      name !== '' &&
      email !== '' &&
      telephone !== '' &&
      password !== '' &&
      typeof availability !== 'undefined' &&
      Gdpr !== false
    ) {
      const aValue = []
      if (skillSets) {
        for (let i = 0; i < skillSets.length; i++) {
          let x = skillSets[i].value
          aValue.push(x)
        }
      }

      const ProfileDetail = {
        name,
        email,
        telephone,
        skills: skillSets,
        role: 'Freelancer',
        linkedIn,
        gitHub,
        cv: cvURL,
        fileName,
        share,
        Gdpr,
        password,
        remoteRate,
        remoteRateNotApplicable,
        onSiteRate,
        onSiteRateNotApplicable,
        availability,
        dateRegistered: moment().format('YYYY/MM/DD'),
      }
      setLoading(true)
      const userRef = firebase.db
        .collection('freelancer')
        .doc(ProfileDetail.email)
      await firebase
        .register(
          ProfileDetail.name,
          ProfileDetail.email,
          ProfileDetail.password
        )
        .then(() => {
          console.log('REGISTER')
        })

      //await firebase.login(ProfileDetail.email, ProfileDetail.password)
      await userRef.set(ProfileDetail, { merge: true })
      await firebase.db
        .collection('users')
        .doc(ProfileDetail.email)
        .set(ProfileDetail)
      await navigate('/thanks')
      setLoading(false)
    } else {
      openModalHandler()
    }
  }

  const getFile = e => {
    let file = e.target.files[0]
    console.log('FILE', file)
    var storageRef = firebase.storage.ref(`CV/${form.email}/${file.name}`)
    if (file) {
      setFileName(file.name)
      console.log('FILE Name', file.name)
      const uploadTask = storageRef.put(file)
      uploadTask.on(
        'state_changed',
        snapshot => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(progress)
        },
        error => {
          console.log('Problem uploading the file, Try again!')
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            setCvURL(downloadURL)
            console.log(downloadURL)
          })
        }
      )
    }
  }

  // handle validation
  let errors = {}
  if (!email) {
    errors.email = ''
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = 'invalid email address'
  }
  if (!password) {
    errors.password = ''
  } else if (password.length < 6) {
    errors.password = 'password must be at least 6 characters'
  }

  let yesterday = Datetime.moment().subtract(1, 'day')
  let valid = function(current) {
    return current.isAfter(yesterday)
  }

  const GDPRlabel = (
    <span>
      I agree to Flexatal’s storage of my data and have read the
      <Link style={{ color: '#ff9000' }} to="/privacy">
        {' '}
        privacy statement
      </Link>
    </span>
  )
  if (loading) {
    return <Spinner />
  } else {
    return (
      <Wrapper>
        <ErrorModal show={isShowing} close={closeModalHandler}>
          Please make sure you have provided us with your name,availability,
          email, telephone number as well as provided a password and accepeted
          our privacy terms to proceed.
        </ErrorModal>
        <form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <Header>Apply as Freelancer</Header>

          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="Your Name..."
                  onChange={e => setValues({ ...form, name: e.target.value })}
                  value={form.name}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="Your Email..."
                  onChange={e => setValues({ ...form, email: e.target.value })}
                  value={form.email}
                />
                <ErrorText>{errors.email}</ErrorText>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="Your Telephone Number..."
                  onChange={e =>
                    setValues({ ...form, telephone: e.target.value })
                  }
                  value={form.telephone}
                />
              </GridItem>
            </GridContainer>

            {user ? (
              <></>
            ) : (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <TextInput
                    type="password"
                    placeholder="Enter a password to login in the future..."
                    onChange={e =>
                      setValues({ ...form, password: e.target.value })
                    }
                    value={form.password}
                  />
                  <ErrorText>{errors.password}</ErrorText>
                </GridItem>
              </GridContainer>
            )}

            <div>
              <div>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <ReactSelect
                      setValue={form.skillSets}
                      onValuePass={value => onValuePass(value)}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <FreelancerOnSiteRate
                  onSiteRateNotApplicable={onSiteRateNotApplicable}
                  getonSiteRateNotApplicable={getonSiteRateNotApplicable}
                  getOnSiteRate={getOnSiteRate}
                  onSiteRate={onSiteRate}
                />
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <FreelancerRemoteRate
                  remoteRateNotApplicable={remoteRateNotApplicable}
                  getremoteRateNotApplicable={getremoteRateNotApplicable}
                  getRemoteRate={getRemoteRate}
                  remoteRate={remoteRate}
                />
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="LinkedIn Profile"
                  onChange={e =>
                    setValues({ ...form, linkedIn: e.target.value })
                  }
                  value={form.linkedIn}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="GitHub Profile"
                  onChange={e => setValues({ ...form, gitHub: e.target.value })}
                  value={form.gitHub}
                />
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{
                    margin: '4% 0',
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid #fff',
                  }}
                >
                  <StyledInput>
                    {fileName === '' ? (
                      <div style={{ color: '#777' }}>
                        Upload your CV{' '}
                        <AttachFile style={{ color: '#ff9000' }} />
                      </div>
                    ) : (
                      fileName
                    )}
                    <input
                      type="file"
                      onChange={getFile}
                      style={{ display: 'none' }}
                    />
                  </StyledInput>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <Datetime
                  isValidDate={valid}
                  inputProps={{
                    placeholder: 'Availability From',
                    readOnly: true,
                  }}
                  onChange={moment =>
                    setValues({
                      ...form,
                      availability:
                        'Availability from ' +
                        moment._d.toLocaleString('en-GB', {
                          timeZone: 'UTC',
                          hour12: false,
                          month: 'short',
                          year: 'numeric',
                          day: 'numeric',
                        }),
                    })
                  }
                  timeFormat={false}
                  dateFormat="LL"
                  value={form.availability}
                  closeOnSelect={true}
                />
              </GridItem>
            </GridContainer>
            <div style={{ marginTop: '4%' }}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <CB checked={Gdpr} label={GDPRlabel} color="#ff9000" />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <CB
                    checked={share}
                    color="#ff9000"
                    onChange={() => setShare(prevValue => !prevValue)}
                    label="I agree to my profile being shared on Flexatal’s website"
                  />
                </GridItem>
              </GridContainer>
            </div>

            <ButtonWrap>
              <Button onClick={saveProfile} round color="warning" size="lg">
                Save
              </Button>
            </ButtonWrap>
          </div>
        </form>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #232323;
  padding: 0 5%;
`

const Header = styled.h1`
  font-size: 5vw;
  color: #ffffff;
  padding: 5% 0 3% 0;
  font-weight: bold;
  text-align: center;
  border-radius: 16px;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 5%;
`

const StyledInput = styled.label`
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  color: white;
  padding: 4px 0;
`
const ErrorText = styled.p`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #f90000;
`
