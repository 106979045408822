import React from 'react'
import styled from 'styled-components'
import Button from 'components/CustomButtons/Button.jsx'

const Wrapper = styled.div`
  background: white;
  border: 1px solid #d0cccc;
  border-radius: 8px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  transition: all 0.8s;
  width: 50%;
  height: 50%;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 5000;
  transform: translate(50%, 50%);
  @media (max-width: 800px) {
    height: 40%;
  }
`

const Header = styled.h3`
  color: #232323;
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-align: center;
`
const Body = styled.div`
  padding: 30px 15px;
  text-align: center;
  font-size: 18px;
  color: #232323;
`

const Footer = styled.div`
  background: white;
  display: flex;
  justify-content: center;
`

const ErrorModal = props => {
  return (
    <Wrapper show={props.show}>
      <Header>Oops something went wrong!</Header>

      <Body>{props.children}</Body>
      <Footer>
        <Button color="rose" onClick={props.close}>
          Close
        </Button>
      </Footer>
    </Wrapper>
  )
}

export default ErrorModal
