import React from 'react'
import styled from 'styled-components'
import '../css/spinner.css'

export default function Spinner(props) {
  return (
    <Wrapper>
      <Heading>{props.heading}</Heading>
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #232323;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const Heading = styled.h1`
  font-size: 48px;
  font-weight: bold;
  padding: 0 5%;
  margin-bottom: 2vh;
  color: white;
`
